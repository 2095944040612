/* src/index.css o src/App.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300..900&display=swap');

/* Tu código adicional */

* {
  font-family: "Darker Grotesque", sans-serif;
}
.card-container {
    display: flex;
    justify-content: center;
    gap: 20px; /* Espacio entre las cards */
    flex-wrap: wrap; /* Permite que las cards se envuelvan si no hay espacio suficiente en el contenedor */
  }

.vert-move1 {
    -webkit-animation: mover1 1s infinite  alternate;
    animation: mover1 1s infinite  alternate;
}
.vert-move1 {
    -webkit-animation: mover1 1s infinite  alternate;
    animation: mover1 1s infinite  alternate;
}
@-webkit-keyframes mover1 {
    0% { transform: translateY(0); }
    10% { transform: translateY(-10px); }
}
@keyframes mover1 {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}


.vert-move2 {
    -webkit-animation: mover2 1s infinite  alternate;
    animation: mover2 1s infinite  alternate;
}
.vert-move2 {
    -webkit-animation: mover2 1s infinite  alternate;
    animation: mover2 1s infinite  alternate;
}
@-webkit-keyframes mover2 {
    0% { transform: translateY(0); }
    10% { transform: translateY(-10px); }
}
@keyframes mover2 {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}


.vert-move3 {
    -webkit-animation: mover3 1s infinite  alternate;
    animation: mover3 1s infinite  alternate;
}
.vert-move3 {
    -webkit-animation: mover3 1s infinite  alternate;
    animation: mover3 1s infinite  alternate;
}
@-webkit-keyframes mover3 {
    0% { transform: translateY(0); }
    100% { transform: translateY(0px); }
}
@keyframes mover3 {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}


.vert-move4 {
    -webkit-animation: mover4 1s infinite  alternate;
    animation: mover4 1s infinite  alternate;
}
.vert-move4 {
    -webkit-animation: mover4 1s infinite  alternate;
    animation: mover4 1s infinite  alternate;
}
@-webkit-keyframes mover4 {
    0% { transform: translateY(0); }
    40% { transform: translateY(-100px); }
}
@keyframes mover4 {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}


.boton-hero{
    background-color: transparent;
  border: 3px #161252 solid;
  color: #161252;
  transition: background-color 0.3s ease;
}
.boton-hero:hover{
    background-color: #161252!important;
    color:white!important
}

.boton-topbar {
    color: #3A337F
}

.boton-topbar:hover{
    background-color:#635BB8;
    color: #FAEEDD;
}

.boton-topbar:active{
    background-color: #FDD46F;
    color: #2D1D46;
}

.pill-hero-one{
    background-color: #A3E0D1;
    color: #1B695F;
}
.pill-hero-two{
    background-color: #FDD46F;
    color: #3A337F;
}

.card-hero{
    border: 2px solid #2D1D46;
}
.card-hero:hover{
    background-color: #FDD46F;
}

.jobseekerbutton{
    background-color: #F58033 ;
    color: #FAEEDD;
}

.jobseekerbutton:hover{
    background-color: #FDD46F;
    color: #F58033;
}

.boton-cta{
    background-color: #B2AFDC;
    color: #3A337F;
}
.boton-cta:hover{
    background-color: #F58033;
    color: #FAEEDD;
    border: 2px solid #FAEEDD;
}

.boton-contact-form{
    background-color: #2D1D46;
}

/* Agrega estos estilos al CSS de tu componente */
.loading {
    position: relative;
    opacity: 0.5;
    pointer-events: none;
  }
  
  .loading::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .loading button {
    cursor: not-allowed;
  }

  .hovertext:hover{
    background-color: #3A337F;
    color: #FAEEDD;
  }